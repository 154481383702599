import React, {useEffect, useContext} from 'react';

import GlobalContext from '../../context/GlobalContext';

const themeConfigDefault = {
    bodyDark: false,
    headerDark: false,
    footerDark: false,
    headerStyle: 'style1',
    headerClassName: '',
    footerStyle: 'style1', //
    footerClassName: 'bg-default-1 pb-13 pb-lg-10',
};

const PageWrapper = ({children, darkTheme = false, themeConfig = null}) => {
    const gContext = useContext(GlobalContext);

    useEffect(() => {
        if (darkTheme) {
            gContext.changeTheme({
                headerDark: true,
                bodyDark: true,
                footerDark: true,
            });
        } else {
            gContext.changeTheme({...themeConfigDefault, ...themeConfig});
        }
    }, []);

    return <>{children}</>;
};

export default PageWrapper;
