import React from "react";
import PageWrapper from "../components/PageWrapper";

import imgE from "../assets/image/inner-page/png/404-error-img.png";
import {OutboundLink} from 'gatsby-plugin-google-gtag';

const NotFoundPage = () => {
    return (
        <>
            <PageWrapper themeConfig={{
                footerStyle: 'none',
                footerClassName: 'pt-13 pt-lg-25 pb-13 pb-lg-10',
            }}>
                <div className="pt-24 pt-md-26 pb-15 pt-lg-33 pb-md-19 pb-lg-25">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div className="text-center">
                                    <div
                                        className="mb-8 mb-lg-16"
                                        data-aos="fade-up"
                                        data-aos-duration={500}
                                    >
                                        <img className="w-100 w-xs-auto" src={imgE} alt="" />
                                    </div>
                                    <h1
                                        className="font-size-13 mb-8"
                                        data-aos="fade-up"
                                        data-aos-duration={700}
                                    >
                                        404 Error
                                    </h1>
                                    <p
                                        className="font-size-7 mb-0"
                                        data-aos="fade-up"
                                        data-aos-duration={900}
                                    >
אין דף בכתובת שהגעת אליה.
                                    </p>
                                    <div>
                                        <div
                                            className="pt-11 max-w-322 mx-auto"
                                            data-aos="fade-up"
                                            data-aos-duration={1100}
                                        >
                                            <OutboundLink
                                                href="https://propertime.co.il"
                                                className="btn btn-bali-gray w-100 rounded-5 text-white"
                                                rel="noopener"
                                            >
                                                חזרה לדף הבית
                                            </OutboundLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default NotFoundPage;
